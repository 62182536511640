define("enkimail-ember-js/templates/docs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEAKpLtG",
    "block": "{\"symbols\":[],\"statements\":[[3,\" Header Docs \"],[2,\"\\n\"],[10,\"header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"intro-text\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"name\"],[12],[2,\"how-to\"],[13],[2,\"\\n          \"],[10,\"hr\"],[14,0,\"star-light\"],[12],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"skills\"],[12],[2,\"docs\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"section\"],[14,1,\"docs\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"How to get an account\"],[13],[2,\"\\n        \"],[10,\"hr\"],[14,0,\"star-primary\"],[12],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"This site is on beta. To get an account you have to make a request sending an email to \"],[10,\"strong\"],[12],[2,\"signup@enkimail.com\"],[13],[2,\" with\\n        your preferred nickname.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "enkimail-ember-js/templates/docs.hbs"
  });

  _exports.default = _default;
});