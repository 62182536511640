define("enkimail-ember-js/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l1MyY++v",
    "block": "{\"symbols\":[],\"statements\":[[3,\" Header Home \"],[2,\"\\n\"],[10,\"header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,0,\"img-responsive\"],[14,\"src\",\"img/cabin-256.png\"],[14,\"alt\",\"enkimail\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"intro-text\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"name\"],[12],[2,\"enkimail\"],[13],[2,\"\\n          \"],[10,\"hr\"],[14,0,\"star-light\"],[12],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"skills\"],[12],[2,\"free pop email accounts\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "enkimail-ember-js/templates/index.hbs"
  });

  _exports.default = _default;
});